.modal-header_v2 {
    padding:          16px;
    background-color: rgba(var(--blue-rgb), 0.05);
    display:          flex;
    align-items:      center;
    justify-content:  space-between;
    font-size:        14px;
    font-style:       normal;
    line-height:      1.715;
    border-bottom:    1px solid rgba(var(--gray-rgb), 0.25);
    width:            100%;
    font-weight:      600;

    icon[name='close'] {
        color:  var(--gray);
        cursor: pointer;
    }
}

.modal-scroll-container {
    padding:        16px;
    display:        flex;
    flex-direction: column;
    gap:            32px;
    width:          100%;
    overflow-y:     auto;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.modal-action-buttons {
    display:         flex;
    padding:         16px;
    align-items:     center;
    justify-content: space-between;
    width:           100%;
    gap:             16px;
    border-top:      1px solid rgba(var(--gray-rgb), 0.25);
}

.cdk-visually-hidden {
    border:             0;
    clip:               rect(0 0 0 0);
    height:             1px;
    margin:             -1px;
    overflow:           hidden;
    padding:            0;
    position:           absolute;
    width:              1px;
    white-space:        nowrap;
    outline:            0;
    -webkit-appearance: none;
    -moz-appearance:    none;
    left:               0
}

[dir=rtl] .cdk-visually-hidden {
    left:  auto;
    right: 0
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
    pointer-events: none;
    top:            0;
    left:           0;
    height:         100%;
    width:          100%
}

.cdk-overlay-container {
    position: fixed;
    z-index:  1000
}

.cdk-overlay-container:empty {
    display: none
}

.cdk-global-overlay-wrapper {
    display:  flex;
    position: absolute;
    z-index:  1000
}

.cdk-overlay-pane {
    position:       absolute;
    pointer-events: auto;
    box-sizing:     border-box;
    z-index:        1000;
    display:        flex;
    max-width:      100%;
    max-height:     100%
}

.cdk-overlay-backdrop {
    position:                    absolute;
    top:                         0;
    bottom:                      0;
    left:                        0;
    right:                       0;
    z-index:                     1000;
    pointer-events:              auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition:                  opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
    opacity:                     0
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 1
}

.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: .6
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, .32)
}

.cdk-overlay-transparent-backdrop {
    transition: visibility 1ms linear, opacity 1ms linear;
    visibility: hidden;
    opacity:    1
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
    opacity:    0;
    visibility: visible
}

.cdk-overlay-backdrop-noop-animation {
    transition: none
}

.cdk-overlay-connected-position-bounding-box {
    position:       absolute;
    z-index:        1000;
    display:        flex;
    flex-direction: column;
    min-width:      1px;
    min-height:     1px
}

.cdk-global-scrollblock {
    position:   fixed;
    width:      100%;
    overflow-y: scroll
}

.cdk-dialog-container {
    outline: 0;
}
