@font-face {
    font-family:  'Poppins';
    font-style:   normal;
    font-weight:  400;
    font-display: swap;
    src:          url('assets/fonts/Poppins-Regular.woff2') format('woff2');
}

@font-face {
    font-family:  'Poppins';
    font-style:   normal;
    font-weight:  500;
    font-display: swap;
    src:          url('assets/fonts/Poppins-Medium.woff2') format('woff2');
}

@font-face {
    font-family:  'Poppins';
    font-style:   normal;
    font-weight:  600;
    font-display: swap;
    src:          url('assets/fonts/Poppins-SemiBold.woff2') format('woff2');
}

@font-face {
    font-family:  'Poppins';
    font-style:   normal;
    font-weight:  700;
    font-display: swap;
    src:          url('assets/fonts/Poppins-Bold.woff2') format('woff2');
}

h1 {
    font-size:   24px;
    line-height: normal;
    font-weight: 500;
}

h2 {
    font-size:   16px;
    line-height: normal;
    font-weight: 500;
}

p {
    font-size:   14px;
    font-weight: 500;
    line-height: 1.7;
}

.bold {
    font-weight: 700;
}

.semi-bold {
    font-weight: 600;
}

