ngx-slider.ngx-slider {
    display:  inline-block;
    position: relative;
    height:   20px;
    width:    100%;
    margin:   12px 0 0;

    .ngx-slider-bar {
        left:             0;
        width:            100%;
        z-index:          1;
        height:           20px;
        border-radius:    20px;
        background-color: rgba(var(--gray-rgb), 0.25);
        cursor:           pointer;
    }

    .ngx-slider-pointer {
        cursor:           pointer;
        width:            20px;
        height:           20px;
        background-color: var(--dark-blue);
        z-index:          3;
        border-radius:    20px;
        top:              0;
        outline:          unset;

        &:after {
            display: none;
        }
    }

    .ngx-slider-bar-wrapper {
        left:        0;
        box-sizing:  border-box;
        margin-top:  0;
        padding-top: 0;
        width:       100%;
        height:      20px;
        z-index:     1;

        .ngx-slider-selection {
            visibility:       visible !important;
            background-color: var(--dark-blue);
            border-radius:    20px 0 0 20px;
        }
    }

    .ngx-slider-tick {
        background-color: transparent;
    }

    .ngx-slider-tick-legend {
        font-size:   12px;
        font-weight: 400;
        white-space: nowrap;
    }

    .ngx-slider-tick {
        width: auto;
    }

    .with-legend {
        margin-bottom: 0;
    }

    .ngx-slider-ticks {
        top: 10px;
    }
}

ngx-slider.ngx-slider.with-legend {
    margin-bottom: 0;
}

range-slider {
    margin-bottom: 30px;
    display:       flex;
    height:        30px;
}

ngx-slider.ngx-slider[disabled] .ngx-slider-bar-wrapper {
    .ngx-slider-selection {
        background-color: #81A4B6;
    }
}

ngx-slider.ngx-slider[disabled] {
    .ngx-slider-pointer {
        background-color: #81A4B6;
    }

    .ngx-slider-tick-legend {
        opacity: .7;
    }
}
