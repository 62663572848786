
.mat-datepicker-content {
    padding-bottom:   6px;
    background-color: transparent !important;
}

.datepicker {
    //variables:
    --mat-focus-indicator-display:                            block;
    --mat-focus-indicator-border-width:                       3px;
    --mat-datepicker-calendar-date-outline-color:             transparent;
    --mat-datepicker-calendar-date-disabled-state-text-color: rgba(26, 27, 31, 0.38);
    --mat-focus-indicator-border-style:                       solid;
    --mat-focus-indicator-border-color:                       transparent;
    --mat-datepicker-calendar-container-text-color:           #1A1B1F;
    --mat-datepicker-calendar-container-elevation-shadow:     0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    --mat-datepicker-calendar-container-shape:                16px;
    --mat-datepicker-calendar-text-font:                      Roboto, sans-serif;
    --mat-datepicker-calendar-text-size:                      0.875rem;
    --mat-datepicker-calendar-body-label-text-size:           0.875rem;
    --mat-datepicker-calendar-body-label-text-weight:         500;
    --mat-datepicker-calendar-period-button-text-color:       #44474E;
    --mdc-text-button-label-text-color:                       #005CBB;
    --mdc-text-button-container-shape:                        9999px;
    --mat-text-button-horizontal-padding:                     12px;
    --mdc-text-button-container-height:                       40px;
    --mdc-text-button-label-text-font:                        Roboto, sans-serif;
    --mdc-text-button-label-text-size:                        0.875rem;
    --mdc-text-button-label-text-tracking:                    0.006rem;
    --mdc-text-button-label-text-weight:                      500;
    --mat-datepicker-calendar-date-selected-state-text-color: #FFFFFF;
    --mat-datepicker-calendar-navigation-button-icon-color:   #44474E;
    --mdc-icon-button-state-layer-size:                       40px;
    --mdc-icon-button-icon-size:                              24px;
    --mat-icon-button-touch-target-display:                   block;
    --mat-icon-button-pressed-state-layer-opacity:            0.12;
    --mat-icon-button-state-layer-color:                      #44474E;
    --mat-icon-button-hover-state-layer-opacity:              0.08;
    //
    background-color:                                         #FFFFFF;
    border-radius:                                            10px;
    box-shadow:                                               0 4px 5px 0 rgba(174, 179, 207, 0.15);
    border:                                                   2px solid var(--light-gray);

    & .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: transparent;
        border:           1px solid var(--dark-blue);
    }

    & .mat-calendar-body-selected.mat-calendar-body-today {
        box-shadow: unset;
        border:     unset;
    }

    & .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        background-color: rgba(var(--blue-rgb), .05);
    }

    & .mat-calendar-body-selected {
        background-color: var(--dark-blue);
    }

    & .mat-mdc-button:not(:disabled) {
        &:hover {
            background-color: rgba(var(--blue-rgb), .05);
        }
    }

    & .mat-calendar-previous-button, .mat-calendar-next-button {
        svg {
            color: var(--dark-blue);
        }
    }
    
    & .mat-calendar-arrow {
        fill: var(--dark-blue);
    }

    & .mat-calendar-table {
        font-size: 12px;
    }

    & .mat-calendar-table-header th {
        font-size: 12px;
    }

    .mat-mdc-icon-button[disabled], .mat-mdc-icon-button.mat-mdc-button-disabled {
        cursor:  unset;
        opacity: 0.4;
    }

    .mat-focus-indicator {
        position: relative
    }

    .mat-focus-indicator::before {
        top:            0;
        left:           0;
        right:          0;
        bottom:         0;
        position:       absolute;
        box-sizing:     border-box;
        pointer-events: none;
        display:        var(--mat-focus-indicator-display, none);
        border:         var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
        border-radius:  var(--mat-focus-indicator-border-radius, 4px)
    }

    .mat-focus-indicator:focus::before {
        content: ''
    }
}
