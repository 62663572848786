/* You can add global styles to this file, and also import other style files */

@use 'scss/application';
@use 'scss/inputs';
@use 'scss/modal';
@use 'scss/reset.scss';
@use 'scss/swiper.scss';
@use 'scss/typography';
@use 'scss/variables';
@use 'scss/common-classes';
@use 'scss/range-slider';
@use 'scss/datetime-picker';
//@use '@angular/material/prebuilt-themes/azure-blue.css';
