html, body {
    height:              100%;
    overscroll-behavior: none;
}

body {
    margin:                      0;
    font-family:                 var(--font-family), serif;
    font-weight:                 500;
    -webkit-font-smoothing:      antialiased;
    -moz-osx-font-smoothing:     grayscale;
    -webkit-tap-highlight-color: transparent;
}

button {
    cursor: pointer;
}

svg {
    display: block;
}

section {
    display:        flex;
    flex-direction: column;
    gap:            16px;
    align-items:    flex-start;
}

// TODO: REMOVE THIS AFTER MODALS WILL BE IMPLEMENTED
modal-companies-filter, modal-employees-filter, modal-phones, modal-search-filter, subsidiary-company {
    width:           100%;
    height:          100%;
    display:         flex;
    align-items:     flex-end;
    justify-content: center;
    @media (min-width: 768px) {
        align-items: center;
    }
}

.main-container {
    position:   relative;
    min-height: 100vh;
}

//.wrapper-input-availability {
//    max-width:  180px;
//    margin-top: 24px;
//}
// TODO: REMOVE AND ADD TO SKILLS
.highlight {
    color:       var(--dark-green);
    font-weight: 600;
}
