//small icon-button
@mixin icon-btn {
    border-radius: 10px;
    display:       flex;
    align-items:   center;
}

@mixin blue-button {
    padding:         8px 16px;
    background:      var(--blue-button-gradient);
    color:           #FFFFFF;
    display:         flex;
    align-items:     center;
    justify-content: center;
    font-size:       16px;
    line-height:     normal;
    transition:      0.3s;
    border-radius:   10px;
    &:hover {
        opacity: 0.8;
    }
}

@mixin gray-button {
    padding:          8px 16px;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    font-size:        16px;
    font-weight:      400;
    line-height:      normal;
    transition:       0.3s;
    border-radius:    10px;
    background-color: var(--light-gray);
    color:            var(--dark-blue);
    flex:             1;
    &:hover {
        background: rgba(var(--blue-rgb), 0.05)
    }
}

@mixin option-buttons {
    display:       flex;
    align-items:   center;
    gap:           8px;
    padding:       8px 16px;
    border-radius: 10px;
    border-width:  1px;
    border-style:  solid;
    font-size:     14px;
    font-weight:   600;
    line-height:   normal;
    &:hover {
        box-shadow: 0 0 10px 0 rgba(174, 179, 207, 0.20);
    }
}
