
:root {
    --font-family:          'Poppins', sans-serif;
    --white:                #FFFFFF;
    --dark:                 #000000;
    --gray:                 #565656;
    --gray-rgb:             86, 86, 86;
    --light-gray:           #F0F1F6;
    --blue:                 #0099A8;
    --blue-rgb:             0, 153, 168;
    --dark-blue:            #1B729E;
    --red:                  #DE440B;
    --dark-red:             #B73809;
    --dark-red-rgb:         183, 56, 9;
    --purple:               #6D69A0;
    --green:                #8DB916;
    --dark-green:           #7DA21A;
    --dark-green-rgb:       125, 162, 26;
    --orange:               #FF9228;
    --dark-orange:          #EA821C;
    --dark-orange-rgb:      234, 130, 28;
    --blue-button-gradient: linear-gradient(91deg, #4298A6 4.88%, #2E7097 96.06%);
    --processbar-gradient:  linear-gradient(240deg, #094D58 15.11%, #1B729E 100%);
    --card-backgroung:      linear-gradient(240deg, rgba(66, 152, 166, 0.20) 15.11%, #1B729E 100%);
    --outline:              linear-gradient(241deg, #94C8D0 3.5%, #2E7097 100%);
    --box-shadow-rgb:       174, 179, 207;
    //
    //--breakpoint-xs:        425px;
    //--breakpoint-sm:        768px;
    //--breakpoint-md:        1024px;
    //--breakpoint-lg:        1440px;
    //--breakpoint-xl:        1920px;
}





