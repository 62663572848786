//@use './button-mixins';
//@use './modal-mixins';
@mixin section-title {
    font-size:   16px;
    line-height: normal;
    color:       var(--gray);
}

@mixin input {
    border-radius: 10px;
    outline:       1px solid rgba(var(--gray-rgb), 0.25);
    border:        unset;
    background:    #FFFFFF;
    font-size:     14px;
    line-height:   1.715;
    color:         rgba(var(--gray-rgb), .75);
    width:         100%;
    cursor:        pointer;
    font-family:   inherit;
    &:hover {
        box-shadow: 0 0 10px 0 rgba(174, 179, 207, 0.20);
    }
}

@mixin select-arrow-element {
    pointer-events:      none;
    position:            absolute;
    background-image:    url('assets/svg/arrow-down.svg');
    display:             block;
    content:             '';
    background-repeat:   no-repeat;
    background-position: center center;
    top:                 50%;
    margin-top:          -12px;
    width:               24px;
    height:              24px;
    inset-inline-end:    16px;
}

@mixin tags-block {
    display:     flex;
    align-items: center;
    gap:         16px;
}

@mixin tag {
    // TODO: CHECK AND REMOVE .tag class
    display:         flex;
    align-items:     center;
    justify-content: center;
    background:      var(--light-gray);
    font-size:       12px;
    font-weight:     400;
    line-height:     normal;
    padding:         6px 12px;
    border-radius:   20px;
    text-align:      center;
}

@mixin anfrage-block {
    padding:        16px;
    border-bottom:  1px solid var(--light-gray);
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    gap:            4px;
    .anfrage-title {
        font-size:   12px;
        font-weight: 400;
        line-height: normal;
    }
    .anfrage-block-content {
        font-size:   14px;
        line-height: 1.715;
        word-break:  break-all;
    }
}

@mixin input-wrapper_v2 {
    position:         relative;
    background-color: var(--white);
    width:            100%;
    display:          flex;
    align-items:      center;
    padding:          12px;
    border-radius:    10px;
    outline:          1px solid rgba(var(--gray-rgb), 0.25);
    cursor:           pointer;
    gap:              8px;
    &:hover {
        box-shadow: 0 0 10px 0 rgba(174, 179, 207, 0.20);
    }
    input {
        outline:     unset;
        font-size:   14px;
        line-height: 1.715;
        color:       rgba(var(--gray-rgb), 0.75);
        width:       100%;
        font-family: inherit;
        position:    relative;
        border:      unset;
        cursor:      pointer;
        padding:     0;

        &::placeholder {
            color: rgba(var(--gray-rgb), 0.5);
        }

        &:not(input:placeholder-shown) ~ .clear-button {
            display: block;
        }
    }
}

// TODO : REMOVE THIS STYLE LATER
@mixin wrapper-input {
    max-width: 100%;
    position:  relative;
}

@mixin before_wrapper_input {
    content:             '';
    background-repeat:   no-repeat;
    background-position: center center;
    display:             block;
    width:               24px;
    height:              24px;
    inset-inline-start:  12px;
    position:            absolute;
    z-index:             1;
    margin-top:          -12px;
    top:                 50%;
}

@mixin drop-arrow {
    position:            absolute;
    content:             '';
    background-image:    url('assets/svg/arrow-down.svg');
    display:             block;
    background-repeat:   no-repeat;
    background-position: center center;
    top:                 50%;
    margin-top:          -12px;
    width:               24px;
    height:              24px;
    inset-inline-end:    0;
    cursor:              pointer;
}

@mixin hidden-select {
    content:          '';
    display:          inline-block;
    width:            10px;
    height:           10px;
    min-width:        10px;
    background-color: transparent;
    border-radius:    50%;
    outline-offset:   2px;
    outline:          2px solid var(--dark-blue);
}

@mixin container {
    padding:        16px 24px;
    display:        flex;
    flex-direction: column;
    border-bottom:  1px solid rgba(var(--gray-rgb), 0.25);
}

@mixin scroll-option {
    display:        flex;
    flex-direction: column;
    width:          100%;
    overflow-y:     auto;
    height:         100%;
    &::-webkit-scrollbar {
        width: 0;
    }
}
