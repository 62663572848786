swiper-container::part(pagination) {
    position:      relative;
    padding-top:   16px;
    margin-bottom: -14px;
}

swiper-container::part(bullet) {
    width:            12px;
    height:           12px;
    opacity:          1;
    background-color: var(--white);
}

swiper-container::part(bullet-active) {
    width:            12px;
    height:           12px;
    background-color: var(--dark-blue);
}
