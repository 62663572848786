html {
    overflow-x:      hidden;
    scroll-behavior: smooth;
}

body,
button,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    padding: 0;
    margin:  0;
}

* {
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color:           inherit;
}

button {
    border:           none;
    font:             inherit;
    color:            inherit;
    background-color: transparent;
    cursor:           pointer;
    outline:          none;
}

button[disabled] {
    opacity:        0.5;
    pointer-events: none;
}

ul {
    list-style: none;
}
