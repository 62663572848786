@use '../scss/mixins-library/button-mixins';
@use '../scss/mixins-library/mixins';

.width-full {
    width: 100%;
}

// TODO: REMOVE AFTER MODALS IMPLEMENTATION
.overlay {
    position:         fixed;
    display:          flex;
    width:            100%;
    height:           100%;
    inset:            0;
    overflow:         hidden;
    z-index:          100;
    transition:       opacity 0.15s ease;
    background-color: rgba(var(--gray-rgb), .2);
    pointer-events:   auto;
    align-items:      center;
    justify-content:  center;
}

.filter-results-content {
    background-color: var(--light-gray);
    color:            var(--gray);
    padding-bottom:   70px;
    min-height:       inherit;
    height:           100vh;
    display:          flex;

    > *:first-child {
        display: none;
    }

    @media (min-width: 768px) {
        padding:    unset;
        overflow-y: hidden;
        > *:first-child {
            display: flex;
        }
    }
}

.pointer {
    cursor: pointer;
}

// TODO remove
.close {
    cursor: pointer;
}

.filters-block {
    flex-direction: column;
    width:          100%;
    display:        flex;
    @media (min-width: 768px) {
        max-width:        390px;
        padding-top:      64px;
        background-color: #FFFFFF;
    }
}

.filter-heading {
    padding:          16px 24px;
    background-color: rgba(var(--blue-rgb), 0.05);
    display:          flex;
    justify-content:  space-between;
    align-items:      center;
    font-size:        14px;

    & + * {
        flex: 1;
    }
}

.reset {
    color: var(--blue);
}

.main-filter-options {
    @include mixins.scroll-option();
    max-height: calc(100vh - 145px);
    @media (min-width: 768px) {
        max-height: calc(100vh - 117px);
    }
}

.icon-input {
    position:            absolute;
    z-index:             1;
    margin-inline-start: 12px;
    top:                 50%;
    margin-top:          -12px;
}

.results-block {
    width:      100%;
    padding:    80px 24px 24px;
    max-height: 100vh;
    overflow-y: scroll;
    position:   relative;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.options-button-block {
    display:          flex;
    gap:              16px;
    z-index:          2;
    justify-content:  space-between;
    align-items:      center;
    position:         fixed;
    background-color: var(--light-gray);
    padding:          16px 24px;
    margin-left:      -24px;
    width:            100%;
    margin-top:       -16px;
    @media (min-width: 768px) {
        max-width:   calc(100% - 300px);
        margin-left: -24px;
        padding:     16px 24px;
    }
    @media (min-width: 1024px) {
        max-width: calc(100% - 390px);
    }
}

.rotate-180 {
    transform: rotate(180deg);
}

.select-button-white {
    @include button-mixins.option-buttons();
    border-color:     var(--dark-blue);
    background-color: #FFFFFF;
    color:            var(--dark-blue);

    &:hover {
        background: rgba(var(--blue-rgb), .05);
    }
}

.deselect-button {
    @include button-mixins.option-buttons();
    border-color:     var(--dark-red);
    background-color: rgba(var(--dark-red-rgb), 0.1);
    color:            var(--dark-red);

    icon.cancel {
        color: var(--dark-red);
    }
}

.profile {
    @include button-mixins.option-buttons();
    border-color:     var(--dark-green);
    background-color: rgba(var(--dark-green-rgb), 0.1);
    color:            var(--dark-green);
}

// TODO: REMOVE LATER
.filter {
    @include button-mixins.icon-btn();
    position:         relative;
    padding:          8px 16px;
    background-color: #FFFFFF;

    &:hover {
        background-color: rgba(var(--blue-rgb), .05);
    }
}

.icon-button {
    @include button-mixins.icon-btn();
    padding:          8px;
    background-color: #FFFFFF;

    &:hover {
        background-color: rgba(var(--blue-rgb), .05);
    }

    @media (min-width: 1024px) {
        gap:         8px;
        white-space: nowrap;
    }

    & span {
        display:   none;
        font-size: 14px;
        color:     var(--dark-blue);
        @media (min-width: 768px) {
            display: block;
            //font-size: 14px;
            //color:     var(--dark-blue);
        }
    }
}

.sorting {
    flex-wrap:      wrap;
    gap:            12px;
    @include mixins.container();
    flex-direction: row;
}

.filter-title {
    position:      relative;
    margin-bottom: 16px;
    width:         100%;
}

.filter-title-drop {
    position:        relative;
    margin-bottom:   16px;
    display:         flex;
    align-items:     center;
    justify-content: space-between;

    &:after {
        @include mixins.drop-arrow();
    }
}

.container-selects {
    display:        flex;
    flex-direction: column;
    gap:            8px;
}

.blue-button {
    @include button-mixins.blue-button();
    flex: 1;
}

.gray-button {
    @include button-mixins.gray-button();
}

.white-button {
    padding:          8px 16px;
    display:          flex;
    align-items:      center;
    justify-content:  center;
    font-size:        14px;
    font-weight:      600;
    line-height:      normal;
    transition:       0.3s;
    border-radius:    10px;
    background-color: #FFFFFF;
    color:            var(--dark-blue);
    flex:             1;
    max-width:        fit-content;
    gap:              8px;

    &:hover {
        background: rgba(var(--blue-rgb), 0.05)
    }
}

.button-container {
    display:         flex;
    padding:         24px;
    align-items:     center;
    justify-content: space-between;
    width:           100%;
    gap:             16px;
}

.options-block {
    width:           100%;
    margin-top:      16px;
    display:         flex;
    gap:             16px;
    flex-direction:  column;
    justify-content: space-between;
    @media (min-width: 1024px) {
        flex-direction: row;
    }
}

.tab-block {
    display:     flex;
    gap:         8px;
    align-items: center;
    flex-wrap:   wrap;
}

.tab {
    color:       var(--dark-blue);
    text-align:  center;
    font-size:   14px;
    font-weight: 500;
    line-height: normal;
    padding:     8px 16px;
    cursor:      pointer;

    &:hover {
        border-radius: 40px;
        background:    rgba(var(--blue-rgb), 0.05);
    }
}

.tab-active {
    border-radius: 40px;
    background:    #FFFFFF;
    color:         var(--gray);
}

.cards-block {
    padding-top:     60px;
    display:         flex;
    gap:             16px;
    flex-wrap:       wrap;
    justify-content: space-between;
    position:        relative;

    candidate-card, contact-card, company-card, watch-card {
        width: 100%;
        @media (min-width: 1024px) {
            width: 48%;
        }
        @media (min-width: 1440px) {
            width: 31%;
        }
    }
}

.search-container {
    display:     flex;
    gap:         16px;
    align-items: flex-start;
    position:    relative;
}

.button_text {
    font-size:   14px;
    font-weight: 500;
    line-height: 1.715;
    color:       var(--dark-blue);
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.description-person {
    flex: 1;
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.staff {
    border-radius: 4px;
    background:    var(--light-gray);
    padding:       11px 12px;
    font-size:     12px;
    font-weight:   400;
    line-height:   normal;
    display:       flex;
    align-items:   center;
    min-width:     fit-content;
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.option-buttons {
    display:         flex;
    gap:             16px;
    justify-content: flex-start;
    align-items:     flex-start;
    @media (min-width: 1024px) {
        justify-content: space-between;
    }
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.heading-info {
    padding:        24px 24px 16px;
    display:        flex;
    flex-direction: column;
    gap:            16px;
    width:          100%;
    border-bottom:  1px solid var(--light-gray);
    @media (min-width: 768px) {
        padding: 24px;
    }
    @media (min-width: 768px) {
        flex-direction:  row;
        justify-content: space-between;
    }
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.avatar-block {
    display: flex;
    gap:     16px;
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.name {
    font-size:     14px;
    font-weight:   600;
    line-height:   1.715;
    margin-bottom: 16px;
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.container-info {
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    max-width:       250px;
    @media (min-width: 768px) {
        align-items: flex-start;
        gap:         12px;
    }
}

// TODO: MOVE TO CANDIDATE PROFILE PAGE
.download-button,
.history-button {
    @include button-mixins.icon-btn();
    background-color: var(--light-gray);
    border:           none;
    gap:              8px;

    icon {
        min-width: 24px;
    }

    &:hover {
        background-color: rgba(var(--blue-rgb), .05);
    }

    @media (min-width: 1024px) {
        padding: 8px 16px 8px 12px;
    }
}

.buttons-container {
    display:         flex;
    gap:             12px;
    padding:         0;
    justify-content: flex-start;
    @media (min-width: 768px) {
        justify-content: flex-end;
    }
}

.tags-block {
    padding:       16px 12px 16px 24px;
    display:       flex;
    flex-wrap:     wrap;
    gap:           16px;
    border-bottom: 1px solid var(--light-gray);
    width:         100%;
}

.tag {
    padding:         12px 16px;
    display:         flex;
    align-items:     center;
    justify-content: center;
    border-radius:   4px;
    background:      var(--light-gray);
    font-size:       12px;
    font-weight:     400;
    line-height:     normal;
}

// TODO: REMOVE LATER
.contacts {
    font-weight:    400;
    line-height:    normal;
    display:        flex;
    gap:            12px;
    flex-direction: column;
}

// TODO: REMOVE LATER
.contact {
    font-size:   12px;
    font-weight: 400;
    display:     flex;
    gap:         8px;
    align-items: center;
}

// TODO: REMOVE LATER
.contact-buttons {
    display: flex;
    gap:     24px;
}

// TODO: REMOVE LATER
.contact-button {
    @include button-mixins.icon-btn();
    padding:          8px 16px 8px 12px;
    background-color: var(--light-gray);
    color:            var(--dark-blue);
    display:          flex;
    gap:              8px;
    font-size:        14px;
    font-weight:      600;
    line-height:      1.715;
    cursor:           pointer;

    &:hover {
        background-color: rgba(var(--blue-rgb), .05);
    }

    @media (min-width: 768px) {
        padding: 8px 16px 8px 12px;
    }
}

.section-title {
    position:        relative;
    padding:         16px 24px;
    border-bottom:   1px solid var(--light-gray);
    display:         flex;
    justify-content: space-between;
    align-items:     center;
    width:           100%;
    font-size:       14px;
    font-weight:     500;
    line-height:     1.715;
    gap:             12px;

    &:after {
        @include mixins.drop-arrow();
        inset-inline-end: 24px;
    }
}

.icon-title {
    border-radius:    4px;
    background-color: var(--light-gray);
    padding:          4px;
}

.text-title {
    flex: 1
}

.section {
    background-color: #FFFFFF;
    border-radius:    10px;
    height:           fit-content;
}

// TODO: CHECK AND REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.scope-activity {
    padding:        16px 24px;
    display:        flex;
    gap:            16px;
    flex-direction: column;
    align-items:    center;
    width:          100%;
    @media (min-width: 1024px) {
        flex-direction: row;
        flex-wrap:      wrap;
    }
}

// TODO: CHECK AND REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.activity {
    width:         100%;
    max-width:     500px;
    padding:       12px 16px;
    font-size:     14px;
    font-weight:   500;
    line-height:   1.715;
    border-radius: 4px;
    text-align:    center;
    background:    var(--light-gray);
    @media (min-width: 1024px) {
        max-width: 387px;
        width:     calc((100% - 16px) / 2);
    }
}

// TODO: REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.general-info {
    padding:   16px 8px;
    display:   flex;
    flex-wrap: wrap;
    gap:       16px;
    @media (min-width: 1024px) {
        flex-wrap:       nowrap;
        justify-content: space-between;
        width:           100%;
        padding:         16px 24px;
    }
}

// TODO: REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.general-item {
    padding:     16px 8px;
    display:     flex;
    gap:         12px;
    align-items: center;
}

// TODO: REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.general-description {
    display:        flex;
    flex-direction: column;
}

// TODO: CHECK AND REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.label {
    font-size:   12px;
    font-weight: 400;
    line-height: normal;
}

// TODO: CHECK AND REMOVE AFTER PROFILE DETAILS WILL BE APPLIED
.information {
    font-size:   14px;
    font-weight: 500;
    line-height: 1.715;
}

// TODO: CHECK USAGES and REMOVE
.history {
    width: 100%;
}

// TODO: CHECK USAGES and REMOVE
.history-item {
    padding:         24px;
    display:         flex;
    gap:             16px;
    justify-content: flex-start;
    align-items:     flex-start;
    border-bottom:   1px solid var(--light-gray);
}

// TODO: CHECK USAGES and REMOVE
.group {
    display:        flex;
    flex-direction: column;
    gap:            8px;
}

// TODO: CHECK USAGES and REMOVE
.period {
    font-size:   14px;
    font-weight: 600;
    line-height: 1.715;
}

// TODO: CHECK USAGES and REMOVE
.expanded-info {
    font-size:   14px;
    font-weight: 500;
    line-height: 1.715;
}

icon.clear-button {
    display:   none;
    cursor:    pointer;
    min-width: 24px;
    height:    24px;
}

spinner {
    width:  100%;
    height: 100%;
}

.main-content {
    display:                    flex;
    flex-direction:             column;
    max-height:                 100vh;
    overflow-y:                 scroll;
    color:                      var(--gray);
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width:   0;
        display: none;
        height:  0;
    }
}

/* Global google map styles */
google-map .map-container {
    width:  100% !important;
    height: 100% !important;

    iframe + div {
        border: 0 !important;
    }
}

//.availability-select-container {
//    display: flex;
//    gap:     12px;
//}

.result-count {
    color:           #FFFFFF;
    display:         flex;
    align-items:     center;
    justify-content: center;
    font-size:       16px;
    line-height:     normal;
    transition:      0.3s;
    border-radius:   10px;
    cursor:          pointer;
    font-weight:     600;

    //&:hover {
    //    opacity: 0.8;
    //}
}

//.group-container {
//    display:        flex;
//    flex-direction: column;
//    gap:            16px;
//}
