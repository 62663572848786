@use './mixins-library/mixins';
// TODO: RENAME TO .checkbox and .radio
.select-wrapper, .select-wrapper-revert {
    position: relative;

    input[type='radio'],
    input[type='checkbox'] {
        position: absolute;
        left:     -9999px;
    }

    label {
        cursor:           pointer;
        display:          flex;
        padding:          6px 16px;
        border-radius:    10px;
        align-items:      center;
        gap:              14px;
        background-color: var(--light-gray);
        color:            var(--dark-blue);
        font-size:        12px;

        &:hover {
            background-color: rgba(var(--blue-rgb), 0.05);
        }
    }

    input[type='radio']:checked + label,
    input[type='checkbox']:checked + label {
        outline: 2px solid var(--dark-blue);
    }
}

.select-wrapper-revert {
    width: fit-content;

    input[type='radio'] + label:after {
        @include mixins.hidden-select();
    }

    input[type='radio']:checked + label:after {
        background-color: var(--dark-blue);
    }
}

.radio-group {
    flex-wrap: wrap;
    gap:       12px;
    display:   flex;
}
